import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "grid md:grid-cols-3 grid-cols-1 gap-8" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "md:col-span-2 col-span-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CourierDetailLeftMenu = _resolveComponent("CourierDetailLeftMenu")
  const _component_CourierProfile = _resolveComponent("CourierProfile")
  const _component_CourierComissionHistory = _resolveComponent("CourierComissionHistory")
  const _component_CourierAttendanceHistory = _resolveComponent("CourierAttendanceHistory")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_CourierDetailLeftMenu, {
          dataFilter: $setup.listMenu,
          positionButton: $setup.posisitionMenu,
          onFilterChange: $setup.changePositionMenu
        }, null, 8, ["dataFilter", "positionButton", "onFilterChange"])
      ]),
      _createVNode("div", _hoisted_3, [
        _createVNode(_Transition, {
          "enter-active-class": "animate__backInUp",
          "leave-active-class": "animate__backInDown"
        }, {
          default: _withCtx(() => [
            ($setup.posisitionMenu === 1)
              ? (_openBlock(), _createBlock(_component_CourierProfile, {
                  key: 0,
                  Id: $setup.decrypt
                }, null, 8, ["Id"]))
              : ($setup.posisitionMenu === 2)
                ? (_openBlock(), _createBlock(_component_CourierComissionHistory, {
                    key: 1,
                    Id: $setup.decrypt
                  }, null, 8, ["Id"]))
                : ($setup.posisitionMenu === 3)
                  ? (_openBlock(), _createBlock(_component_CourierAttendanceHistory, {
                      key: 2,
                      Id: $setup.decrypt
                    }, null, 8, ["Id"]))
                  : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}