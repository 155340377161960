import { courierUseCase } from '@/domain/usecase'
import {
  ref,
  onMounted,
  getCurrentInstance
} from 'vue'
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'
import Lightgallery from '@/views/shared-components/lightgallery/index.vue'
import { useRouter } from 'vue-router'
import moment from 'moment'
import {
  environment
} from '@/utils'
import { useStore } from 'vuex'

export default {
  name: 'CourierProfile',
  components: {
    Lightgallery
  },
  props: {
    Id: {
      default: () => null
    }
  },
  setup(props: any) {
    const app = getCurrentInstance()
    const { $toast, $strInd } = app!.appContext.config.globalProperties
    const router = useRouter()
    const store = useStore()
    const data = ref({})
    const API_BASE = environment.getApiBase()

    /** Get Data Profile */
    const getProfile = () => {
      courierUseCase.getProfileCourier(props.Id).then((result: any) => {
        if (result.error) {
          $toast.add({
            severity: 'error', detail: result.message, group: 'bc', life: 1500
          })
        } else {
          data.value = result.result
        }
      })
    }

    /** TO Detail */
    const toDetail = () => {
      const dataEnc = encryptDecriptMethods.encrypt(props.Id)
      router.push({
        name: 'master-courier-edit',
        params: {
          courierId: dataEnc
        }
      })
    }

    /** function untuk filter list documents berdasrkan jenis */
    const filterDocuments = (list: any, jenis: any) => list.filter((x: any) => x.Jenis === jenis)

    onMounted(() => {
      getProfile()
    })

    enum TypeDocumentEnum {
      ktp,
      sertifikat,
      stnk
    }

    const labelDocument = (obj: any) => {
      /* eslint-disable no-else-return */
      if (obj.Jenis === TypeDocumentEnum[0]) {
        return 'No. KTP / SIM'
      } else if (obj.Jenis === TypeDocumentEnum[1]) {
        return 'Covid-19 Vaksin'
      } else if (obj.Jenis === TypeDocumentEnum[2]) {
        return 'Kendaraan & STNK'
      } else {
        return ''
      }
    }
    return {
      data,
      API_BASE,
      toDetail,
      moment,
      filterDocuments,
      labelDocument,
      store
    }
  }
}
