
/* eslint-disable indent */
import {
  ref,
  onMounted,
  getCurrentInstance
} from 'vue'
import CourierDetailLeftMenu from '@/views/master/courier/detail/CourierDetailLeftMenu.vue'
import CourierProfile from '@/views/master/courier/detail/profile/CourierProfile.vue'
import CourierComissionHistory from '@/views/master/courier/detail/comission-history/CourierComissionHistory.vue'
import CourierAttendanceHistory from '@/views/master/courier/detail/attendance-history/CourierAttendanceHistory.vue'
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'
import { useRoute, useRouter } from 'vue-router'
import iconMenu from './assetsMenuDependency'

const icon = iconMenu()
export default {
  components: {
    CourierDetailLeftMenu,
    CourierProfile,
    CourierComissionHistory,
    CourierAttendanceHistory
  },
  setup() {
    const posisitionMenu = ref(1)
    const route = useRoute()
    const router = useRouter()
    const decrypt = encryptDecriptMethods.decrypt(route.params.courierId)
    const listMenu = ref([{
        // imgSelect: icon.red.pencil,
        // imgNone: icon.dark.pencil,
        imgSelect: `<img src='${require('@/assets/img/icon/pencil-outline-red.svg')}' style='width:20px;height:20px;'/>`,
        imgNone: `<img src='${require('@/assets/img/icon/pencil-outline-grey.svg')}' style='width:20px;height:20px;'/>`,
        label: 'Data Profile Kurir',
        key: 1
      },
      {
        imgSelect: `<img src='${require('@/assets/img/icon/moneyComissionRed.svg')}'/>`,
        imgNone: `<img src='${require('@/assets/img/icon/moneyComissionBlueGrey.svg')}'/>`,
        label: 'Komisi Kurir',
        key: 2
      },
      {
        imgSelect: icon.red.clock,
        imgNone: icon.dark.clock,
        label: 'Log Absensi',
        key: 3
      }
    ])

    const changePositionMenu = (val: any) => {
      posisitionMenu.value = val.key
    }
    return {
      listMenu,
      posisitionMenu,
      changePositionMenu,
      decrypt
    }
  }
}
